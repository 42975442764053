/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        if ($('.slaideri').length) {
          $('.slaideri').slick();
        }

        $(document).scroll(function () {
          var $nav = $(".fixed-top");
          $nav.toggleClass('scrolled', $(this).scrollTop() > 100);
        });

        $(document).on('click', '.button--modalopen', function(e) {

            e.preventDefault();

            $('#generalmodal').find('.modal-body').html('<div class="text-center"><img src="/dist/images/ajax-loader.gif" /></div>');
        });


        $('#generalmodal').on('show.bs.modal', function (event) {

            var button = $(event.relatedTarget),
                actiontype = button.data('action'),
                person_id = button.data('id'),
                nonce = button.data('nonce');

            if (actiontype === 'personcard') {
            // If necessary, you could initiate an AJAX request here (and then do the updating in a callback).
            // Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.

                $.ajax({
                  type: 'POST',
                  url: SsaAjax.ajaxurl,
                  data: {
                    action: 'get_person_card',
                    modaltype: actiontype,
                    nonce: nonce,
                    item_id: person_id
                  },
                  success: function( result ) {
                    var modal = $('#generalmodal');
                    modal.find('.modal-body').html(result);
                  },
                  error: function( result ) {
                    alert("An error occured: " + result.status + " " + result.statusText);
                  }
                });


            }

        });


        $( document ).on('click', '#topbtn', function(e) {
            e.preventDefault();
            $("html, body").animate({ scrollTop: 0 }, 300);
        });

        // Gets the video src from the data-src on each button

        var $videoSrc;

        $('.button--play').click(function() {
            $videoSrc = $(this).data( "src" );
        });

        // when the modal is opened autoplay it
        $('#videomodal').on('shown.bs.modal', function (e) {

            // set the video src to autoplay and not to show related video. Youtube related video is like a box of chocolates... you never know what you're gonna get
            $("#video").attr('src',$videoSrc + "?autoplay=1&amp;modestbranding=1&amp;showinfo=0" );

        });

        // stop playing the youtube video when I close the modal
        $('#videomodal').on('hide.bs.modal', function (e) {
            // a poor man's stop video
            $("#video").attr('src',$videoSrc);
        });


        var $grid = $('.galleryflex').imagesLoaded( function() {
          // init Masonry after all images have loaded
          $grid.masonry({
            // options...
            itemSelector: '.imgwrap',
            columnWidth: '.imgwrap'
          });
        });

        $('.photoswipe').each( function() {
          var $pic     = $(this),
            getItems = function() {
              var items = [];
              $pic.find('a').each(function() {
                var $href   = $(this).attr('href'),
                  $title  = $(this).find('img').attr('alt'),
                  $size   = $(this).data('size').split('x'),
                  $width  = $size[0],
                  $height = $size[1];

                var item = {
                  src : $href,
                  w   : $width,
                  h   : $height,
                  title : $title
                };

                items.push(item);
              });
              return items;
            };

          var items = getItems();

          var $pswp = $('.pswp')[0];
          $pic.on('click', 'figure', function(event) {
              event.preventDefault();

              var $index = $('a', this).data('index');
              console.log($index);
              var options = {
                  index: $index,
                  bgOpacity: 0.9,
                  showHideOpacity: true
              };

              // Initialize PhotoSwipe
              var lightBox = new PhotoSwipe($pswp, PhotoSwipeUI_Default, items, options);
              lightBox.init();
          });

        });

        $(document).on('click', 'a[href^="#"]:not(.moreinfo)', function (event) {
            event.preventDefault();

            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top - 60
            }, 800);
        });

        const mq = window.matchMedia( "(min-width: 768px)" );

        if (mq.matches) {

            // $('.textcols__col--content').matchHeight({ byRow: true });
            $('.latest__first--texts').matchHeight({ byRow: false });
            $('.latest__item--texts').matchHeight({ byRow: false });
            $('.latest__small--texts').matchHeight({ byRow: false });
            $('.block__company--texts').matchHeight({ byRow: true });
            $('.block__iconboxes--box').matchHeight({ byRow: true });
            $('.block__details--wrap').matchHeight({ byRow: true });
            $('.block__infoboxes--row').matchHeight({ byRow: true });
            $('.block__kiinteistot--item').matchHeight({ byRow: true });

            $('.articles__box--texts').matchHeight({ byRow: true });

        } else {
         // window width is less than 500px
        }

        $('.topbar__search').on('click', function(e) {
            e.preventDefault();
            $('.topbar').slideToggle( "fast", function() {
                $(this).find('.search-field').focus();
            });
            // $('.topbar__search').fadeOut();
        });

        $('.close-search').on('click', function(e) {
            e.preventDefault();
            $('.topbar').slideToggle( "fast" );
            // $('.topbar__search').fadeIn();
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        // $('.toimenpide--nosto a').matchHeight({ byRow: true });

      }
    },
    // Home page
    'archive': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

        $('.articles__box--texts').matchHeight({ byRow: true });

      }
    },
    // Home page
    'blog': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS

        $('.articles__box--texts').matchHeight({ byRow: true });

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
